import * as React from 'react'
import NavBar from 'components/NavBar'
import { ReactTabulator } from 'react-tabulator'
import { useAuth0 } from 'react-auth0-spa'
import axios from 'axios'
import Loading from 'components/Loading'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useLocation } from 'react-router-dom'
import humanize from 'humanize-plus'
import { saveAs } from 'file-saver'
import fukutsu from '../images/fukutsu.png'
import kuroneko from '../images/kuroneko.png'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const numOrBlank = (cell: any) => {
  const v = cell.getValue()
  if (v === 0) {
    return ''
  }
  return v
}

const OrdersPage: React.FC = () => {
  const { getTokenSilently } = useAuth0()
  const [token, setToken] = React.useState<string | undefined>('')
  const [orders, setOrders] = React.useState<any[] | null>(null)
  const [groupBy, setGroupBy] = React.useState('orderDate')
  const [filterKeyword, setFilterKeyword] = React.useState<string>('')
  const [filterExampleUse, setFilterExampleUse] = React.useState<0 | 1>(0)
  const tableRef = React.useRef<any>()
  const query = useQuery()

  // トークンセット
  React.useEffect(() => {
    getTokenSilently().then((t: string | undefined) => {
      setToken(t)
      axios.defaults.headers.common['Authorization'] = t
    })
  }, [getTokenSilently])

  React.useEffect(() => {
    const k = query.get('k')
    if (k) {
      setFilterKeyword(k)
    }
  }, [query])

  React.useEffect(() => {
    const groupBy = localStorage.getItem('groupBy')
    if (groupBy) {
      setGroupBy(groupBy)
    }
  }, [])

  const fetchOrders = React.useCallback(() => {
    setOrders(null)
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/ordersummaries?keyword=${filterKeyword}&exampleUse=${filterExampleUse}`,
      )
      .then((res) => {
        if (res.data.data) {
          setOrders(res.data.data)
        } else if (res.data.data === null) {
          setOrders([])
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [filterKeyword, filterExampleUse])

  /**
   * 発送データダウンロード処理
   */
  const downloadShipmentData = React.useCallback(
    (selectedIds: number[], deliveryProvider: string) => {
      axios({
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/download-shipment-data`,
        method: 'post',
        data: { orderIds: selectedIds, deliveryProvider: deliveryProvider },
        responseType: 'blob',
      })
        .then((res) => {
          const blob = new Blob([res.data])
          const now = dayjs().format('YYYY-MM-DD_HHmmss')
          let fileName = ''
          switch (deliveryProvider) {
            case 'yubin':
              fileName = `※個人情報取扱注意※【ゆうプリR用】発送データ_${now}.csv`
              break
            case 'kuroneko':
              fileName = `※個人情報取扱注意※【B2クラウド用】発送データ_${now}.csv`
              break
            case 'fukutsu':
              fileName = `※個人情報取扱注意※【iSTAR-2用】発送データ_${now}.csv`
              break
          }
          saveAs(blob, fileName)
        })
        .catch((err) => {
          alert(err.response.statusText)
        })
    },
    [],
  )

  /**
   * 発送データアップロード処理
   * B2Cloudで出力したCSVを取り込んで、伝票番号を自動入力する
   */
  const uploadShipmentData = React.useCallback((ev) => {
    const file = ev.currentTarget.files[0]
    const formData = new FormData()
    formData.append('file', file)
    axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/upload-shipment-data`,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(() => {
        alert('送信成功しました。データを再取得してください。')
      })
      .catch((err) => {
        alert(err.response.statusText)
      })
  }, [])

  /**
   * 発送データ住所チェック処理
   */
  const checkShipmentData = React.useCallback(
    (deliveryProvider: string) => {
      if (!tableRef || !tableRef.current) {
        return
      }
      const table = tableRef.current.table
      const selectedData = table.getSelectedData()
      if (!selectedData) {
        return
      }
      for (const d of selectedData) {
        if (d.shippingDate === 0) {
          alert('発送予定日が入力されていない注文が選択されています')
          return
        }
      }
      const selectedIds = selectedData.map((d: any) => {
        return d.orderId
      })
      axios({
        url: `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/check-shipment-data`,
        method: 'post',
        data: { orderIds: selectedIds },
      })
        .then((res) => {
          if (res.data !== '') {
            alert(res.data)
          } else {
            downloadShipmentData(selectedIds, deliveryProvider)
          }
        })
        .catch((err) => {
          alert(err.response.statusText)
        })
    },
    [tableRef, downloadShipmentData],
  )

  const clearSelection = React.useCallback(() => {
    if (!tableRef || !tableRef.current) {
      return
    }
    const table = tableRef.current.table
    table.deselectRow()
  }, [tableRef])

  // データ取得
  React.useEffect(() => {
    if (token === '') {
      return
    }
    fetchOrders()
  }, [token, fetchOrders])

  React.useEffect(() => {
    if (!tableRef || !tableRef.current) {
      return
    }
    const table = tableRef.current.table
    if (!table) {
      return
    }
    if (groupBy === 'shippingDateText') {
      table.setFilter('shippingDate', '>', '0')
      table.setSort([{ column: 'shippingDate', dir: 'asc' }])
    } else {
      table.clearFilter()
      table.clearSort()
    }
  }, [tableRef, groupBy])

  if (!orders) {
    return <Loading text="注文情報読み込み中..." />
  }

  const navigateToOrder = (e: any, cell: any) => {
    window.open(`/orders/${cell.getData().orderId}`)
  }

  const toggleAdditionalInformation = (e: any, cell: any) => {
    const orderId = cell.getData().orderId
    cell.setValue(!cell.getValue())
    const printed = cell.getData().printed ? 1 : 2
    const cut = cell.getData().cut ? 1 : 2
    const data = {
      order_id: orderId,
      printed: printed,
      cut: cut,
    }
    axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/order-additional-information`,
      method: 'post',
      data: data,
    })
      .then((res) => {
        if (res.status === 200) {
          const rowData = cell.getData()
          const row = cell.getRow()
          const printedAt = cell.getRow().getCell("printedAt")
          if (printed === 1) {
            printedAt.setValue(Math.round(new Date().getTime()/1000))
          } else {
            printedAt.setValue(0)
          }
          if (
            (rowData.printedAt > 0 && rowData.shippingInfoChangedAt > 0) &&
            rowData.printedAt < rowData.shippingInfoChangedAt
          ) {
            row.getElement().classList.add('shippiing-changed')
          } else {
            row.getElement().classList.remove('shippiing-changed')
          }
        }
      })
      .catch((err) => {
        console.error(err)
        window.alert('エラーが発生しました')
      })
  }

  const columns = [
    {
      title: '支払番号',
      field: 'purchaseId',
      cssClass: 'cursor-default',
      width: 100,
      frozen: window.innerWidth > 1280 ? true : false,
    },
    {
      title: '注文番号',
      field: 'orderId',
      align: 'center',
      cssClass: 'hover:bg-gray-400',
      width: 130,
      cellClick: navigateToOrder,
      formatter: (cell: any) => {
        const v = cell.getValue()
        if (v === 0) {
          return '--'
        }
        return v
      },
      frozen: window.innerWidth > 1280 ? true : false,
    },
    {
      title: 'リピート',
      field: 'repeatId',
      align: 'center',
      cssClass: 'cursor-default',
      width: 130,
      formatter: (cell: any) => {
        const v = cell.getValue()
        if (v === 0) {
          return '--'
        }
        return v
      },
      frozen: window.innerWidth > 1280 ? true : false,
    },
    {
      title: '注文日時',
      field: 'orderDateTime',
      align: 'center',
      cssClass: 'cursor-default',
      width: 150,
      formatter: (cell: any) => {
        const unixtime = cell.getValue() * 1000
        if (unixtime === 0) {
          return '--'
        }
        return dayjs(unixtime).format('YYYY/MM/DD HH:mm')
      },
      frozen: window.innerWidth > 1280 ? true : false,
    },
    {
      title: '発送予定日',
      field: 'shippingDate',
      align: 'center',
      cssClass: 'cursor-default',
      width: 110,
      formatter: (cell: any) => {
        const unixtime = cell.getValue() * 1000
        if (unixtime === 0) {
          return '--'
        }
        return dayjs(unixtime).format('YYYY/MM/DD')
      },
      frozen: window.innerWidth > 1280 ? true : false,
    },
    {
      title: '配送情報変更日時',
      field: 'shippingInfoChangedAt',
      align: 'center',
      cssClass: 'cursor-default',
      width: 150,
      headerSort: false,
      formatter: (cell: any) => {
        const unixtime = cell.getValue() * 1000
        if (unixtime === 0) {
          return '--'
        }
        return dayjs(unixtime).format('YYYY/MM/DD HH:mm')
      },
      frozen: window.innerWidth > 1280 ? true : false,
    },
    {
      title: '組織名',
      field: 'organization',
      align: 'left',
      cssClass: 'cursor-default',
      width: 150,
      frozen: window.innerWidth > 1280 ? true : false,
    },
    {
      title: '氏名',
      field: 'userName',
      align: 'left',
      cssClass: 'cursor-default',
      width: 80,
      frozen: window.innerWidth > 1280 ? true : false,
    },
    {
      title: '商品名',
      field: 'orderName',
      cssClass: 'cursor-default',
      width: 200,
    },
    {
      title: '材質',
      field: 'materialName',
      cssClass: 'cursor-default',
      width: 120,
    },
    {
      title: 'ラミネート加工',
      field: 'lamination',
      cssClass: 'cursor-default',
      width: 120,
    },
    {
      title: 'X(&#13212;)',
      field: 'sizeX',
      align: 'right',
      cssClass: 'cursor-default',
      width: 50,
      headerSort: false,
    },
    {
      title: 'Y(&#13212;)',
      field: 'sizeY',
      align: 'right',
      cssClass: 'cursor-default',
      width: 50,
      headerSort: false,
    },
    {
      title: 'ｻｲｽﾞ(&#13216;)',
      field: 'size',
      align: 'right',
      cssClass: 'cursor-default',
      width: 60,
      headerSort: false,
    },
    {
      title: '枚数',
      field: 'num',
      align: 'right',
      cssClass: 'cursor-default',
      width: 70,
    },
    {
      title: '納期',
      field: 'shippingDays',
      align: 'right',
      cssClass: 'cursor-default',
      width: 72,
      formatter: numOrBlank,
      headerSort: false,
    },
    {
      title: '商品価格',
      field: 'amountProduct',
      align: 'right',
      cssClass: 'cursor-default',
      width: 80,
      formatter: 'money',
      formatterParams: { thousand: ',', symbol: '¥', precision: false },
      headerSort: false,
    },
    {
      title: 'オプション価格',
      field: 'amountOption',
      align: 'right',
      cssClass: 'cursor-default',
      width: 80,
      formatter: 'money',
      formatterParams: { thousand: ',', symbol: '¥', precision: false },
      headerSort: false,
    },
    {
      title: '計算後価格',
      field: 'amountTotal',
      align: 'right',
      cssClass: 'cursor-default',
      width: 80,
      formatter: 'money',
      formatterParams: { thousand: ',', symbol: '¥', precision: false },
      headerSort: false,
    },
    {
      title: 'ポイント数',
      field: 'point',
      align: 'right',
      cssClass: 'cursor-default',
      width: 70,
      headerSort: false,
    },
    {
      title: '支払い方法',
      field: 'purchaseMethod',
      align: 'center',
      cssClass: 'cursor-default',
      width: 80,
      formatter: 'lookup',
      formatterParams: {
        epsilon: 'カード',
        bank: '銀行振込',
        onDelivery: '代金引換',
        gmoab: '後払い',
        vbank: 'V口座',
        paypay: 'PayPay',
        point: '全額P',
      },
      headerSort: false,
    },
    {
      title: 'ステータス',
      field: 'status',
      align: 'center',
      cssClass: 'cursor-default',
      width: 120,
      formatter: 'lookup',
      formatterParams: {
        '0': '不明',
        '1': '注文完了',
        '2': '支払完了',
        '3': '入稿完了',
        '4': '再入稿待ち',
        '5': 'データチェック完了',
        '7': '印刷完了',
        '8': '発送完了',
        '9': '納品完了',
        '10': '入金待ち',
        '12': 'お客様確認中',
        '90': 'キャンセル依頼完了',
        '91': '全額返金完了',
        '92': '規定額返金完了',
        '99': 'キャンセル完了',
      },
    },
    {
      title: '配送業者',
      field: 'deliveryProvider',
      align: 'left',
      cssClass: 'cursor-default',
      width: 80,
      headerSort: false,
    },
    {
      title: '配送伝票番号',
      field: 'trackingNumber',
      align: 'left',
      cssClass: 'cursor-default',
      width: 80,
      headerSort: false,
    },
    {
      title: '備考',
      field: 'memo',
      align: 'left',
      cssClass: 'cursor-default',
      width: 80,
      headerSort: false,
    },
    {
      title: '印刷',
      field: 'printed',
      width: 40,
      align: 'center',
      cssClass: 'hover:opacity-50',
      cellClick: toggleAdditionalInformation,
      formatter: 'tickCross',
      formatterParams: {
        allowEmpty: false,
        allowTruthy: true,
        tickElement:
          "<span class='text-green-700'><i class='fa fa-check-square'></i></span>",
        crossElement:
          "<span class='text-gray-700'><i class='far fa-square'></i></span>",
      },
      headerSort: false,
    },
    {
      title: 'ｶｯﾄ',
      field: 'cut',
      width: 40,
      align: 'center',
      cssClass: 'hover:opacity-50',
      cellClick: toggleAdditionalInformation,
      formatter: 'tickCross',
      formatterParams: {
        allowEmpty: false,
        allowTruthy: true,
        tickElement:
          "<span class='text-green-700'><i class='fa fa-check-square'></i></span>",
        crossElement:
          "<span class='text-gray-700'><i class='far fa-square'></i></span>",
      },
      headerSort: false,
    },
    {
      title: '紹介',
      field: 'canExampleUse',
      width: 40,
      align: 'center',
      cssClass: 'cursor-default',
      formatter: 'tickCross',
      formatterParams: {
        allowEmpty: false,
        allowTruthy: true,
        tickElement:
          "<span class='text-green-700'><i class='fa fa-check-square'></i></span>",
        crossElement:
          "<span class='text-gray-700'><i class='far fa-square'></i></span>",
      },
      headerSort: false,
    },
    {
      title: '印刷日時',
      field: 'printedAt',
      align: 'left',
      cssClass: 'cursor-default',
      width: 0,
      headerSort: false,
    },
  ]

  const handleInputKeyword = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    const k = ev.currentTarget['filter-keyword'].value
    window.history.pushState(null, '', `?k=${k}`)
    setFilterKeyword(k)
  }

  const clearTabulatorPersistence = () => {
    localStorage.setItem('tabulator-backofficeOrders-filter', '')
    localStorage.setItem('tabulator-backofficeOrders-sort', '')
    localStorage.setItem('tabulator-backofficeOrders-columns', '')
    window.location.reload()
  }
  const todayText = dayjs().format('YYYY年MM月DD日')
  return (
    <>
      <header>
        <NavBar />
      </header>
      <div className="flex flex-col">
        <div className="h-8 p-2 flex justify-between">
          <div className="flex items-center justify-start">
            <form onSubmit={handleInputKeyword}>
              フィルタ:{' '}
              <input
                className="border border-solid border-gray-300 rounded px-2"
                name="filter-keyword"
                type="text"
                defaultValue={filterKeyword}
              />
              <button type="submit" className="hidden" />
            </form>
            <button
              className="border border-solid border-gray-300 px-4 rounded hover:bg-gray-200 mx-2"
              onClick={fetchOrders}
            >
              データ再取得
            </button>
            <label className="mx-1 flex items-center justify-center">
              <input
                type="checkbox"
                name="filterExampleUse"
                checked={filterExampleUse === 1}
                onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => {
                  if (ev.currentTarget.checked) {
                    setFilterExampleUse(1)
                  } else {
                    setFilterExampleUse(0)
                  }
                }}
              />
              <span className="text-sm ml-1">紹介OK</span>
            </label>
            <div className="flex text-sm border ml-2">
              <span className="bg-gray-200 p-1">表示切り替え: </span>
              <label className="px-1 flex items-center justify-center hover:bg-gray-100 cursor-pointer">
                <input
                  type="radio"
                  name="groupby"
                  checked={groupBy === 'orderDate'}
                  onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => {
                    if (ev.currentTarget.checked) {
                      setGroupBy('orderDate')
                      localStorage.setItem('groupBy', 'orderDate')
                    }
                  }}
                />
                <span className="text-sm ml-1">注文日ごと</span>
              </label>
              <label className="px-1 flex items-center justify-center hover:bg-gray-100 cursor-pointer">
                <input
                  type="radio"
                  name="groupby"
                  checked={groupBy === 'shippingDateText'}
                  onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => {
                    if (ev.currentTarget.checked) {
                      setGroupBy('shippingDateText')
                      localStorage.setItem('groupBy', 'shippingDateText')
                    }
                  }}
                />
                <span className="text-sm ml-1">発送予定日ごと</span>
              </label>
            </div>
            <div className="flex items-center border px-2 mx-2">
              <div className="text-sm">選択データ: </div>
              <div className="px-px">
                <button
                  className="border border-solid border-gray-300 px-4 rounded hover:bg-gray-200 mx-px"
                  onClick={clearSelection}
                >
                  選択解除
                </button>
              </div>
              <div className="text-sm ml-1">発送用CSV: </div>
              <div className="px-px">
                <button
                  className="border border-solid border-gray-300 p-1 rounded bg-white hover:opacity-75 mx-px"
                  onClick={() => checkShipmentData('fukutsu')}
                >
                  <img
                    src={fukutsu}
                    alt="iSTAR-2用CSVデータダウンロード"
                    width={20}
                    height={20}
                  />
                </button>
              </div>
              <div className="px-px">
                <button
                  className="border border-solid border-gray-300 p-1 rounded bg-white hover:opacity-75 mx-px flex items-center"
                  onClick={() => checkShipmentData('kuroneko')}
                >
                  <span className="text-xs">ダウンロード:</span>
                  <img
                    src={kuroneko}
                    alt="B2クラウド用CSVデータダウンロード"
                    width={20}
                    height={20}
                  />
                </button>
              </div>
              <div className="px-px">
                <label className="border border-solid border-gray-300 p-1 rounded bg-white hover:opacity-75 mx-px flex items-center cursor-pointer">
                  <input
                    type="file"
                    name="uploadShipmentData"
                    className="hidden"
                    onChange={uploadShipmentData}
                  />
                  <span className="text-xs">アップロード:</span>
                  <img
                    src={kuroneko}
                    alt="B2クラウド用CSVデータアップ"
                    width={20}
                    height={20}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <button
              className="border border-solid border-gray-300 px-4 rounded hover:bg-gray-200 mx-2"
              onClick={clearTabulatorPersistence}
            >
              テーブル設定初期化
            </button>
          </div>
        </div>
        <ReactTabulator
          ref={tableRef}
          height={window.innerHeight - 80}
          data={orders}
          columns={columns}
          tooltips={true}
          layout="fitColumns"
          options={{
            groupBy: groupBy,
            persistence: true,
            persistenceMode: 'local',
            persistenceID: 'backofficeOrders',
            persistenceLayout: true,
            movableColumns: true,
            virtualDomBuffer: 20000,
            groupStartOpen: (
              value: any,
              count: number,
              data: any,
              group: any,
            ) => {
              if (groupBy === 'orderDate') {
                return true
              }
              let open = false
              if (value.startsWith(todayText)) {
                open = true
              }
              return open //all groups with more than three rows start open, any with three or less start closed
            },
            groupToggleElement: 'header',
            groupHeader: function (
              value: any,
              count: number,
              data: any,
              group: any,
            ) {
              let unshipped = 0
              let unprinted = 0
              let uncut = 0
              let amount = 0
              let v180 = 0
              for (const d of data) {
                if (![8, 9].includes(d.status)) {
                  unshipped++
                }
                if (d.printed === false) unprinted++
                if (d.cut === false) uncut++
                amount = amount + d.amountTotal
                v180 = v180 + d.sheetNumV180
              }

              if (groupBy === 'orderDate') {
                return `<div class="flex items-center">
                <span><big class="font-bold">${value}</big></span>
                <span style='margin-left:10px;width:40px;text-align:right;'>${count}件</span>
              ${
                Number(amount) > 0
                  ? `
              <span style='width:120px;text-align:right;'>
                <big style='margin-left:10px;font-weight:700;color:#a00;'>&yen;${humanize.intComma(
                  amount,
                )}</big>
              </span>
              `
                  : `<span></span>`
              }
              </div>`
              }
              return `<div class="flex items-center">
              <span><big class="font-bold">${value}</big></span>
              <span style='margin-left:10px;width:40px;text-align:right;'>${count}件</span>
              <div class="flex items-center" style='margin-left:10px;'>
                <div>[</div>
                <div style="width: 120px;"><span>未印刷:</span> <span style="display:inline-block;text-align:right;width:40px;"><big style='font-weight:700;color:#a00;'>${unprinted}</big></span></div>
                <div style="width: 120px;"><span>未カット:</span><span style="display:inline-block;text-align:right;width:40px;"><big style='font-weight:700;color:#a00;'>${uncut}</big></span></div>
                <div style="width: 120px;"><span>未発送:</span>  <span style="display:inline-block;text-align:right;width:40px;"><big style='font-weight:700;color:#a00;'>${unshipped}</big></span></div>
                <div>]</div>
              </div>
              <div class="flex items-center" style='margin-left:10px;'>
                <div>[</div>
                <div style="width: 120px;"><span>V180:</span>  <span style="display:inline-block;text-align:right;width:40px;"><big style='font-weight:700'>${v180}</big></span></div>
                <div>]</div>
              </div>
              ${
                Number(amount) > 0
                  ? `
              <span style='width:120px;text-align:right;'>
                <big style='margin-left:10px;font-weight:700;color:#a00;'>&yen;${humanize.intComma(
                  amount,
                )}</big>
              </span>
              `
                  : `<span></span>`
              }
            </div>`
            },
            selectable: true,
            rowFormatter: (row: any) => {
              var data = row.getData()

              if ([0, 1, 2, 3, 4, 10, 12].includes(data.status)) {
                row.getElement().classList.add('no-progress-order')
              }

              if (
                (data.printedAt > 0 && data.shippingInfoChangedAt > 0) &&
                data.printedAt < data.shippingInfoChangedAt
              ) {
                row.getElement().classList.add('shippiing-changed')
              } else {
                row.getElement().classList.remove('shippiing-changed')
              }

              if (isBefore15hPreviousBusinessDay(data.orderDateTime, 1)) {
                if ([3].includes(data.status)) {
                  row.getElement().classList.add('user-uploaded')
                }
              }

              if ([5].includes(data.status)) {
                switch (data.shippingDays) {
                  case 3:
                    if (isBefore15hPreviousBusinessDay(data.orderDateTime, 1)) {
                      row.getElement().classList.add('not-printed-yet')
                    }
                    break
                  case 5:
                    if (isBefore15hPreviousBusinessDay(data.orderDateTime, 2)) {
                      row.getElement().classList.add('not-printed-yet')
                    }
                    break
                  case 7:
                    if (isBefore15hPreviousBusinessDay(data.orderDateTime, 2)) {
                      row.getElement().classList.add('not-printed-yet')
                    }
                    break
                }
              }
            },
          }}
        />
      </div>
    </>
  )
}

export default OrdersPage

function isBefore15hPreviousBusinessDay(targetUnix: number, offset: number) {
  // targetTimeをdayjsオブジェクトに変換
  const target = dayjs.unix(targetUnix)

  const today = dayjs()
  // 曜日を取得 (0: Sunday, 1: Monday, ..., 6: Saturday)
  const dayOfWeek = today.day()

  // 前営業日を計算
  let previousBusinessDay
  if (dayOfWeek === 1) {
    // Monday
    previousBusinessDay = today.subtract(2 + offset, 'day') // Previous business day is Friday
  } else if (dayOfWeek === 0 || dayOfWeek === 7) {
    previousBusinessDay = today.subtract(dayOfWeek + offset, 'day') // Previous business day is Friday
  } else {
    previousBusinessDay = today.subtract(offset, 'day') // Previous business day is the previous day
  }

  // 前営業日の15時の時刻を作成
  const previousBusinessDay15 = previousBusinessDay
    .set('hour', 15)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)
  return target.isBefore(previousBusinessDay15)
}
