import { productNameById } from './product'

export const isMultisticker = (order: any) => {
  // 全抜きかつ、カットラインの本数2本以上の場合はマルチステッカー扱い
  if (order.delivery_form === 128 && order.halfcut >= 32) {
    return true
  }
  return false
}

export const optionsText = (order: any, products: any) => {
  let result = ``
  result = `${result}ホワイトインキ:${productNameById(
    products,
    'whiteInk',
    order.white_ink,
  )},`
  result = `${result}ラミネーション:${productNameById(
    products,
    'lamination',
    order.lamination,
  )},`
  result = `${result}接着剤:${productNameById(
    products,
    'adhesive',
    order.adhesive,
  )},`
  result = `${result}カットラインの本数:${productNameById(
    products,
    'halfcut',
    order.halfcut,
  )}\n`
  result = `${result}カットパス:${productNameById(
    products,
    'cutpath',
    order.cutpath,
  )},`
  result = `${result}裏スリット:${productNameById(
    products,
    'slit',
    order.slit,
  )},`
  result = `${result}裏面印刷:${productNameById(
    products,
    'backSide',
    order.back_side,
  )},`
  result = `${result}仕上がり形態:${productNameById(
    products,
    'deliveryForm',
    order.delivery_form,
  )}\n`
  result = `${result}控え送付${productNameById(
    products,
    'sendCopy',
    order.send_copy,
  )},`
  result = `${result}カットライン作成代行S:${productNameById(
    products,
    'cutlineService',
    order.cutline_service,
  )},`
  result = `${result}ホワイトデータ作成代行S:${productNameById(
    products,
    'whitedataService',
    order.whitedata_service,
  )}`
  return result
}
